<template>
  <v-app>
    <v-main>
      <v-overlay
        :value="this.loadingOverlay" 
        :opacity="1"
        color="white"
        style="z-index:1001;"
      >
        <v-card color="white" elevation="0">
          <v-row class="d-flex justify-center">
            <v-img
              src="img/mikan.svg"
              max-width="160"
              width="120"
              min-width="120"
              class="mx-n16"
            >
            </v-img>
          </v-row>
          <div class="site-title-text mt-10" style="color: #000000">reimikaInfo</div>
        </v-card>
      </v-overlay>
      <v-container>
        <v-row>
          <v-col cols="12" class="pa-0">
            <div class="d-flex">
              <div
                class="site-title-text ml-1"
              >
                reimikaInfo
              </div>
              <v-divider vertical class="my-4 ml-5"></v-divider>
              <v-dialog 
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="fade-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    outlined
                    x-large
                    color="orange"
                    style="top:9px; right:15px; position:fixed; z-index:1000;"
                    elevation="0"
                    @click="dialog=!dialog"
                  >
                    <v-icon
                      v-if="!dialog"
                      color="orange"
                    >
                      mdi-menu
                    </v-icon>
                    <v-icon
                      v-else-if="dialog"
                      color="orange"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <div class="site-title-text text-center">
                    Menu
                  </div>
                  <v-divider class="mb-6"></v-divider>
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      v-for="item in this.menuItems"
                      :key="item[0]"
                      two-line
                      class="text-center"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 6 : 0" @click="menuTextClick(item[2])">
                        <v-list-item-content>
                          <div class="menu-name-text text--orange"
                            
                          >
                            {{ item[0] }}
                          </div>
                          <v-list-item-subtitle class="mt-2">
                            {{ item[1] }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-card>
                      </v-hover>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                    >
                      <div class="menu-name-text text--orange"
                      >
                        Circle
                      </div>
                      <div class="mt-6 ml-4"
                      >
                        同人サークル
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-flex justify-center mb-5 pa-0"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 6 : 0">
                          <v-img
                            src="img/mikanFactorySQ.jpg"
                            max-width="200"
                            @click="menuTextClick('mikanFactory')"
                          >
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <router-view/>
      <v-fab-transition>
        <v-btn
          color="orange"
          fab
          dark
          small
          absolute
          right
          style="left:20px; bottom:20px; position:fixed;"
          @click="$vuetify.goTo(0)"
          v-show="buttonActive"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>
      <page-footer>
      </page-footer>
    </v-main>
  </v-app>
</template>

<script>
import PageFooter from './components/PageFooter.vue'

export default {
  name: 'App',

  components: {
    PageFooter
  },

  data: () => ({
    dialog: false,
    loadingOverlay: true,
    buttonActive: false,
    scroll: 0,
    menuItems: [
      ["Profile", "自己紹介", "/"],
      ["Photo", "写真ギャラリー", "Photo"],
      //["Program", "作ったプログラム", "Program"],
      ["Movie", "動画ギャラリー", "Movie"],
    ]
  }),

  mounted() {
    let route = this.$route;
    this.setMeta(route);
    window.addEventListener('scroll', this.scrollWindow) // スクロールの位置を監視
    if (this.$route.path == "/") {
      setTimeout(function () {
        this.hideLoadingOverlay();
      }.bind(this), 1000);
    }
    else {
      this.hideLoadingOverlay();
    }
  },

  methods: {
    scrollWindow() {
      const top = 200
      this.scroll = window.scrollY
      if (top <= this.scroll) { // 200px以上スクロールした場合のみボタンを表示
        this.buttonActive = true
      } else {
        this.buttonActive = false
      }
    },
    showLoadingOverlay() {
      this.loadingOverlay = true;
    },
    hideLoadingOverlay() {
      this.loadingOverlay = false;
    },
    menuTextClick(to) {
      this.dialog = false;
      this.$router.push(to);
    },
    setMeta(route){
      // タイトルを設定
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    },
  },

  watch: {
    '$route': function (to) {
      this.setMeta(to);
    }
  }

};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti&family=Noto+Serif+JP:wght@300&family=Poppins:wght@300&family=Source+Code+Pro:ital,wght@1,300&display=swap');  .site-title-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.00em;
    font-size: 2.8rem;
  }
  .menu-btn {
    position: sticky;
  }
  .menu-name-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
    font-size: 30pt;
  }
  .main-en-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
  }
</style>