<template>
  <v-col cols="6" class="pa-0" style="position:relative;">
    <v-btn
      class="ma-0 pa-0"
      block
      x-large
      height="250"
      :color="this.btnColor"
      tile
      elevation="0"
      @click="this.linkOpen"
    >
      <v-icon
        v-if="this.iconName"
        color="white"
        size="130"
      >
        {{ this.iconName }}
      </v-icon>
      <v-img
        v-else-if="this.imgPath"
        :src="this.imgPath"
        max-width="180"
        width="120"
        min-width="120"
        class="mx-n16"
      >
      </v-img>
    </v-btn>
    <v-expand-x-transition>
      <div
        v-show="show"
        class="btn-text"
        style="right:10px; bottom:1px; position:absolute;"
      >
        {{ this.btnText }}
      </div>
    </v-expand-x-transition>
  </v-col>
</template>

<script>
export default {
  props: ["btnColor", "iconName", "imgPath", "link", "btnText"],
  data: () => ({
    show: false,
  }),
  methods: {
    linkOpen() {
      if (this.link) {
        window.open(this.link, '_blank')
      }
    },
  },
  mounted() {
    setTimeout(function() {
      this.show = true;
    }.bind(this), 1800);
  },
}
</script>

<style scoped>
  .btn-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
    font-size: 20pt;
    color: #FFFFFF;
    user-select: none;
  }

  .btn-text::first-letter {
    font-size: 30pt;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>