<template>
  <div>
    <!-- 画像一覧 -->
    <v-row v-if="!this.loading">
      <v-img
          v-for="(img, idx) in displayDatas"
          :key="img.key"
          :src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + img.key + '/thum'"
          :width="imgHeight"
          aspect-ratio="1.4"
          contain
          @click="showImageDialog(img.key, idx)"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="orange"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-row>
    <!-- ローディングスペース表示 -->
    <v-row v-else>
      <v-sheet
        height="100vh">
      </v-sheet>
    </v-row>
    <!-- タグ一覧 -->
    <v-row
      class="d-flex justify-center mt-5 mx-5"
    >
      <v-chip-group
        v-model="selectTag"
        active-class="primary--text"

        v-show="this.tags.length"
        @change="changeShowTag()"
        show-arrows
      >
        <v-chip
          v-for="(tag, index) in tags"
          :key="index"
          label
          color="orange"
          text-color="white"
        >
          <v-avatar
            left
            color="orange darken-3"
          >
            {{ groupsCnt[tag] }}
          </v-avatar>
          {{ tag }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <v-row class="d-flex justify-center mt-5 mx-3">
      <v-divider></v-divider>
    </v-row>
    <!-- ページネーション -->
    <v-row class="d-flex justify-center mt-5 mb-4 mx-5">
      
      <v-pagination
        v-model="page"
        :length="this.pageLength"
        :show-current="true"
        @input = "pageChange"
        color="orange"
        circle
      ></v-pagination>
    </v-row>
    <!-- 画像ポップアッププレビュー -->
    <v-dialog 
      v-model="imgDialog"
      fullscreen
      hide-overlay
      transition="fade-transition"
    >
      <v-sheet color="grey darken-4">
        <v-img
          :src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + this.showImgId + '/thum'"
          :key="this.showImgId + '_backgroud'"
          style="height: 100vh;"
          class="blur-effect"
          gradient="to top right, rgba(25,32,72,.50), rgba(100,115,201,.50)"
        >
          <v-container v-if="imgDialog" class="no-blur-effect">
            <v-row style="height: 100vh;" >
              <v-col cols="12" >
                <v-img
                  :lazy-src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + this.showImgId + '/thum'"
                  :src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + this.showImgId + '/public'"
                  :key="this.showImgId"
                  style="height: 75vh; margin-top: 8vh;"
                  contain
                  v-touch="{
                    right: function(){showBackImage()},
                    left: function(){showNextImage()}
                  }"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        size="40"
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <!-- 操作メニュー -->
                <v-row class="justify-center">
                  <v-col cols="12" lg="8" xl="6">
                    <v-card
                      color="grey darken-3"
                      shaped
                      class="ma-2"
                    >
                      <div class="d-flex justify-space-between mt-2 pa-1">
                        <v-btn
                          icon
                          @click="showBackImage()"
                        >
                          <v-icon color="white">
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="imageClose()"
                        >
                          <v-icon color="white">
                            mdi-close
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="showNextImage()"
                        >
                          <v-icon color="white">
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                      <v-divider color="white" v-show="this.showImgTags"></v-divider>
                      <v-chip-group
                        active-class="primary--text"
                        class="mx-3"
                        center-active
                        style="width: 60vw;"
                        v-show="this.showImgTags"
                      >
                        <v-chip
                          v-for="(tag, index) in showImgTags"
                          :key="index"
                          label
                          color="orange"
                          text-color="white"
                        >
                          <v-icon left>
                            mdi-label
                          </v-icon>
                          {{ tag }}
                        </v-chip>
                      </v-chip-group>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "PhotoGallery",
  props: ["galleryName"],
  data() {
    return {
      data: null,
      groupedData: null,
      groupsCnt: null,
      fullData: null,
      page: 1,
      displayDatas: null,
      pageSize: 12,
      pageLength: 1,
      imgDialog: false,
      showImgId: null,
      showImgIdx: null,
      loading: true,
      tags: [],
      selectTag: null,
      showImgTags: [],
    }
  },
  mounted() {
    fetch("https://royal-rain-a15b.reimika25259469.workers.dev/" + this.galleryName + "/")
      .then(response => response.json())
      .then(data => {
        this.data = data;
        this.fullData = data;
        this.displayDatas = this.data.slice(0,this.pageSize);
        this.pageLength = Math.ceil(this.data.length/this.pageSize);

        const groups = {};
        const groupsCnt = {};
        
        this.data.forEach((item) => {
          if (item.tags) {
            item.tags.forEach((tag) => {
              if (!groups[tag]) {
                groups[tag] = [];
                groupsCnt[tag] = 0;
              }
              groups[tag].push(item);
              groupsCnt[tag]++;
            });
          }
        });

        this.groupedData = groups;
        this.groupsCnt = _.cloneDeep(groupsCnt);
        this.tags =  _.keys(this.groupedData);
        this.tags = _.orderBy(this.tags, tag => this.groupsCnt[tag], 'desc');

        // クエリから画像初期表示
        if (Number(this.$route.query.page) > 0) {
          this.pageChange(Number(this.$route.query.page));
          const imageId = this.$route.query.id;
          if (imageId) {
            // カード再指定
            document.querySelector("meta[name='twitter:image']").setAttribute('content', 'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + imageId + '/thum')
            let idx = 0;
            for (let item of this.displayDatas) {
              if (item.key == imageId) {
                this.showImageDialog(imageId, idx);
                break;
              } else {
                idx++;
              }
            }
          }
        }

        this.loading = false;
      })
      .catch(error => {
        this.error = "An error occurred: " + error.message;
      });
  },
  computed: {
    imgHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "50vw"
        case 'sm': return "30vw"
        case 'md': return "25vw"
        case 'lg': return "20vw"
        case 'xl': return "20vw"
      }
      return null;
    }
  },
  methods: {
    pageChange: function(pageNumber){
      this.page = pageNumber;
      this.displayDatas = this.data.slice(this.pageSize*(pageNumber -1), this.pageSize*(pageNumber));
    },
    showImageDialog(id, idx) {
      this.showImgId = id;
      this.showImgIdx = idx;
      this.showImageReflesh();
      this.imgDialog = true;
    },
    showNextImage() {
      if (this.displayDatas.length <= (this.showImgIdx + 1)) {
        this.showImgIdx = 0;
        // ページ切り替え判定
        if (this.pageLength == this.page) {
          this.page = 1;
        }
        else {
          this.page++;
        }
        this.pageChange(this.page);
      }
      else {
        this.showImgIdx++;
      }
      this.showImageReflesh();
    },
    showBackImage() {
      if (1 > this.showImgIdx) {
        // ページ切り替え判定
        if (1 == this.page) {
          this.page = this.pageLength;
        }
        else {
          this.page--;
        }
        this.pageChange(this.page);
        this.showImgIdx = this.displayDatas.length - 1;
      }
      else {
        this.showImgIdx--;
      }
      this.showImageReflesh();
    },
    showImageReflesh() {
      this.showImgId = this.displayDatas[this.showImgIdx].key;
      this.showImgTags = this.displayDatas[this.showImgIdx].tags;
    },
    changeShowTag() {
      if (_.isUndefined(this.selectTag)) {
        this.data = _.cloneDeep(this.fullData);
      }
      else {
        this.data = this.groupedData[this.tags[this.selectTag]];
      }
      this.displayDatas = this.data.slice(0,this.pageSize);
      this.pageLength = Math.ceil(this.data.length/this.pageSize);
      this.page = 1;
    },
    imageClose() {
      this.imgDialog = !this.imgDialog;
      this.$router.push(
        {
          path: 'photo',
          query: {
            gallery: this.$route.query.gallery
          }
        }
      );
    }
  },
  watch: {
    'showImgId': function () {
      this.$router.push(
        {
          path: 'photo',
          query: {
            gallery: this.$route.query.gallery,
            page: this.page,
            id: this.showImgId
          }
        }
      );
    }
  }
}
</script>

<style scoped>
  .v-dialog__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blur-effect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
  }
  .no-blur-effect {
    backdrop-filter: none;
  }
</style>