<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-sheet class="ml-2" height="100%" width="3px" color="orange">
      </v-sheet>
      <div class="ml-2 title">{{title}}</div>
    </v-col>
    <v-col cols="12">
      <div ref="videoContainer" class="youtube" @click="replaceVideo" style="width: 100%; position: relative; padding-bottom: 56.25%;">
        <img :src="thumbnailUrl" alt="Video Thumbnail" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: ["videoId", "title"],
  computed: {
    thumbnailUrl() {
      return `http://img.youtube.com/vi/${this.videoId}/mqdefault.jpg`;
    },
  },
  methods: {
    replaceVideo() {
      const newVideoDiv = document.createElement("div");
      newVideoDiv.classList.add("youtube");
      newVideoDiv.style = "width: 100%; position: relative; padding-bottom: 56.25%;"
      const newIframe = document.createElement("iframe");
      newIframe.src = `https://www.youtube.com/embed/${this.videoId}`;
      newIframe.frameborder = "0";
      newIframe.allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";
      newIframe.setAttribute("allowfullscreen", "");
      newIframe.style = "position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;"
      newVideoDiv.appendChild(newIframe);
      const videoContainer = this.$refs.videoContainer;
      videoContainer.parentNode.replaceChild(newVideoDiv, videoContainer);
    },
  },
};
</script>

<style scoped>
  .title {
    font-family: 'Noto Serif JP', serif;
    letter-spacing: -0.05em;
  }
</style>