var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.topImageSocialShow),expression:"topImageSocialShow"}]},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-carousel',{attrs:{"cycle":"","interval":"3500","height":"500","hide-delimiters":"","show-arrows-on-hover":""}},_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"height":"500","lazy-src":'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + slide.key + '/thum',"src":'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + slide.key + '/public'}})],1)}),1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('social-btn',{attrs:{"btnColor":"blue","iconName":"mdi-twitter","btnText":"Twitter","link":"https://twitter.com/reitoumikan28"}}),_c('social-btn',{attrs:{"btnColor":"pink darken-1","iconName":"mdi-instagram","btnText":"Instagram","link":"https://www.instagram.com/reitoumikan28"}}),_c('social-btn',{attrs:{"btnColor":"red darken-1","iconName":"mdi-youtube","btnText":"Youtube","link":"http://www.youtube.com/user/reitoumikan28"}}),_c('social-btn',{attrs:{"btnColor":"blue-grey lighten-4","imgPath":"img/hatenablog-logo.svg","btnText":"Blog","link":"http://reitoumikan.hateblo.jp"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('v-lazy',{attrs:{"options":{
            threshold: .5
          },"transition":"fade-transition"}},[_c('div',{staticClass:"title-text text-center"},[_vm._v(" Profile ")])]),_c('v-divider',{staticClass:"mx-5"})],1),_c('v-col',{staticClass:"my-2 d-flex justify-center",attrs:{"cols":"12"}},[_c('v-lazy',{attrs:{"options":{
            threshold: .5
          },"transition":"fade-transition"}},[_c('v-img',{attrs:{"src":"img/icon.png","max-width":"400"}})],1)],1),_c('v-col',{staticClass:"my-2 d-flex justify-center",attrs:{"cols":"12"}},[_c('v-lazy',{attrs:{"options":{
            threshold: .5
          },"transition":"fade-transition"}},[_c('div',{staticClass:"main-text mx-5 text-center"},[_vm._v(" れいミカと申します。"),_c('br'),_vm._v(" 秋田出身で仙台在住のしがない社会人 兼 アイマスPです。"),_c('br'),_vm._v(" こちらでは「自己紹介サイト」と第して、様々な創作物をまとめてみました。少しでもお楽しみ頂けたら幸いです。 ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }