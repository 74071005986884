<template>
  <v-container>
    <v-row class="justify-center mt-10">
      <div
        class="main-en-text"
        style="font-size: 3rem;"
      >
        404 NotFound
      </div>
    </v-row>
    <v-row class="justify-center">
      <v-img
        class="rotate"
        height="20vh"
        contain
        src="img/404bocch.png"
        @click="rotate"
      ></v-img>
    </v-row>
    <v-row class="justify-center mb-10">
      <div
        class="main-en-text"
        style="font-size: 2rem;"
        @click="goTop"
      >
        Home
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundView',
  title: '404 NotFound',
  methods: {
    rotate() {
      document.querySelector(`.rotate`).animate(
        [
          { transform: 'rotate(0deg)' },
          { transform: 'rotate(360deg)' }
        ],
        {
          duration: 500,
          easing: 'ease-in-out',
        }
      );
    },
    goTop() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
  
</style>