<template>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="12" sm="4">
        <v-carousel
          cycle
          height="275px"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
          >
            <v-img
              height="275px"
              :src="slide"
              contain
            >
            </v-img>
          </v-carousel-item>

        </v-carousel>
        
      </v-col>
      <v-col cols="12" sm="8" class="my-2">
        <div
          class="title-text text-center"
        >
          Cricle About
        </div>
        <v-divider class="mx-0"></v-divider>
        <div
          class="main-text text-left mt-3" style="font-size: 1.4rem;"
        >
          ２０１９年より活動開始。<br>
          主にデレマス系フィギュア写真集を頒布。<br>
          コミックマーケットの出展が中心だが、今後は地元仙台やオンリーイベントにも出展予定。
        </div>
        <div class="main-text text-right" style="font-size: 1.0rem;">出展歴<br>C97 / エアコミケ2 / C99 / C101 / 杜の奇跡29</div>
      </v-col>
      <v-col cols="12">
        <div
          class="title-text text-center mt-3"
        >
          Link
        </div>
        <v-divider class="mx-0"></v-divider>
      </v-col>
      <v-row class="d-flex justify-center">
        <v-col cols="12" lg="8" xl="6" class="d-flex justify-center">
          <v-img
            src="img/Melonbooks_logo.svg"
            class="mx-4"
            width="18vw"
            contain
            @click="linkOpen('https://www.melonbooks.co.jp/circle/index.php?circle_id=111746')"
          >
          </v-img>
          <v-icon
            size="85"
            class="mx-4"
            color="blue"
            @click="linkOpen('https://twitter.com/mikanFactory428')"
          >
            mdi-twitter
          </v-icon>
          <v-img
            src="img/BOOTH_logo.png"
            class="mx-4"
            width="18vw"
            contain
            @click="linkOpen('https://reitoumikan.booth.pm/')"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-col cols="12">
        <div
          class="title-text text-center mt-3"
        >
          Gallery
        </div>
        <v-divider class="mx-0"></v-divider>
        <div
          class="main-text text-center mt-3" style="font-size: 1.3rem;"
        >
          （整備中）
        </div>
        <v-carousel
          :show-arrows="false"
          cycle
          height="330px"
        >
          <v-carousel-item
            v-for="(galleryData, i) in galleryDatas"
            :key="i"
          >
            <v-row>
              <v-col cols="4">
                <v-img
                  height="275px"
                  :src="galleryData.imgSrc"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="8">
                <v-card tile elevation="0">
                  <v-card-title>
                    <div style="white-space: pre-wrap;" v-text="galleryData.title"></div>
                  </v-card-title>
                  <v-card-subtitle>
                    <div style="white-space: pre-wrap;" v-text="galleryData.subtitle"></div>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div style="white-space: pre-wrap;" v-text="galleryData.disp"></div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CircleView',
  title: '蜜柑工房',
  data: () => ({
    slides: [
      "img/mikanFactory.png",
      "https://pbs.twimg.com/media/FlRCgoPakAIuvAd?format=jpg&name=medium",
      "https://pbs.twimg.com/media/FH5clIBaAAAxndd?format=jpg&name=medium",
      "https://pbs.twimg.com/media/EM_j58qU8AABn5_?format=jpg&name=medium"
    ],
    galleryDatas: [
      {
        title: "渋谷凛フィギュア写真集\n「凛がいてくれたから。」Phase3",
        subtitle: "44p / B5サイズ / フルカラーRGB",
        disp: "渋谷凛のフィギュア写真集(Figma+1/12ピコニーモ、プライズフィギュアなどを掲載)です。風光明媚な自然を背景にしてみたり、ウェディングドレス姿をいろいろなロケーションで切り抜いてみたり、いろんな服を着てもらったりと、渋谷凛にフォーカスした写真集です。速水奏さんや神崎蘭子ちゃん、トウカイテイオーなどのフィギュア写真や個人的CGコンステライブルポもこっそり掲載してます。",
        imgSrc: "https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/7ae8ba51-5edd-4408-0efe-67deee3f2100/thum"
      },
      {
        title: "渋谷凛フィギュア写真集\n「凛がいてくれたから。II」",
        subtitle: "44p / B5サイズ / フルカラーRGB",
        disp: "2020年頃～2021年秋にかけて撮影した\n渋谷凛のフィギュア写真をまとめた写真集第２弾。Figma・プライズフィギュアなどを中心に、39枚の写真を掲載。",
        imgSrc: "https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/de4ec1cf-1792-44e4-6ac6-9761859bef00/thum"
      },
      {
        title: "渋谷凛フィギュア写真集\n「凛がいてくれたから。」",
        subtitle: "38p / B5サイズ / フルカラー",
        disp: "2018年頃～2019年秋にかけて撮影した、渋谷凛のフィギュア写真集第1作。\nFigma・プライズフィギュアを中心とし、他アイドルのフィギュア写真も掲載。",
        imgSrc: "https://pbs.twimg.com/media/Edlpq1tUMAAkD-h?format=jpg&name=medium"
      },
      {
        title: "渋谷凛ぬいぐるみ写真集\n「凛ぬいといっしょ。」",
        subtitle: "12p / 横A5サイズ / フルカラー",
        disp: "渋谷凛のぬいぐるみ写真をまとめた写真集",
        imgSrc: "https://pbs.twimg.com/media/EKGysMSU0AEneIX?format=jpg&name=large"
      },
      {
        title: "渋谷凛ウエディングFigma写真集\n「an everlasting love」",
        subtitle: "24p / A5サイズ / フルカラー",
        disp: "「渋谷凛×ウエディング」と第して、様々なシチュエーションの\n写真を1冊にまとめた写真集。",
        imgSrc: "https://pbs.twimg.com/media/Fa5d1-MaUAEXJOb?format=jpg&name=large"
      },
      {
        title: "テイオーといっしょ。",
        subtitle: "20p / A5サイズ / フルカラー",
        disp: "ウマ娘「トウカイテイオー」のフィギュア写真集です。\n私服のほか、水着・ウエディングドレス姿など様々な姿で駆け抜けます。",
        imgSrc: "https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/e3ee1ddc-7344-4de4-863a-561af70eb000/thum"
      },
      {
        title: "C97合同文集企画\n「渋谷凛未来概念」",
        subtitle: "文庫サイズ / モノクロ",
        disp: "渋谷凛、二十三歳。「八年後の彼女はどうなっているのか―」\n七つのＳＳ、そしてお寄せ頂いたつぶやき。それぞれが思う彼女の未来が、ここに集結しました。",
        imgSrc: "https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/911cb8a9-366d-459c-a675-a50ebc091f00/thum"
      },
    ]
  }),
  methods: {
    linkOpen(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
  }
}
</script>

<style scoped>
  .title-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
    font-size: 2.4rem;
  }
  .main-text {
    font-family: 'Noto Serif JP', serif;
    letter-spacing: -0.05em;
  }
</style>