<template>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="12">
        <div
          class="title-text text-center"
        >
          Movie
        </div>
        <v-divider class="mx-0"></v-divider>
      </v-col>
      <v-col cols="12" v-for="movie in movieList" :key="movie.id">
        <you-tube-link :videoId="movie.id" :title="movie.title"></you-tube-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import YouTubeLink from '@/components/YouTubeLink.vue'
export default {
  name: "MovieView",
  components: {YouTubeLink},
  data: () => ({
    movieList: [
      {title: "AnemoneStar (Over Myself Edition) - 渋谷凛", id: "cMsgBQ9yAeM"},
      {title: "【水木聖來誕生祭2019】Break a Liar", id: "uKKQMzlQATU"},
      {title: "【デレステ創作MV】Stage Bye Stage - ニュージェネレーションズ", id: "JytCkfOON3M"},
      {title: "【鉄道PV】LOVE特急こまち-20th-", id: "KY2FA1lmbBs"},
      {title: "陸羽東線 鳴子峡通過", id: "ERoLoIMGrtA"},
      {title: "月刊G4U凛ちゃん 4月号", id: "LiZLaypkr_o"},
    ]
  }),
}
</script>

<style scoped>
  .title-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
    font-size: 2.4rem;
  }
</style>