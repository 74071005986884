import Vue from 'vue'
import VueRouter from 'vue-router'
import ProfileView from '../views/ProfileView.vue'
import PhotoView from '../views/PhotoView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import CircleView from '../views/CircleView.vue'
import MovieView from '../views/MovieView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Profile',
    component: ProfileView,
    meta: {
      title: 'reimikaInfo',
      desc: 'れいミカ(@reitoumikan28)の自己紹介サイトです。フィギュア写真撮ったりプログラム書いたりする渋谷凛Pです。'
    }
  },
  {
    path: '/Photo',
    name: 'Photo',
    component: PhotoView,
    meta: {
      title: 'reimikaInfo - Photo',
      desc: '写真のページです。'
    }
  },
  {
    path: '/mikanFactory',
    name: 'circle',
    component: CircleView,
    meta: {
      title: 'reimikaInfo - 蜜柑工房',
      desc: 'サークル「蜜柑工房」のページです。'
    }
  },
  {
    path: '/movie',
    name: 'Movie',
    component: MovieView,
    meta: {
      title: 'reimikaInfo - Movie',
      desc: '動画のページです。'
    }
  },
  {
    path: '*',
    name: '404 NotFound',
    component: NotFoundView,
    meta: {
      title: 'reimikaInfo - 404 NotFound',
      desc: ''
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
