<template>
  <v-container>
    <v-row>
      <v-tabs
        v-model="currentTab"
        color="orange"
        right
        show-arrows
        @change="tabChange"
      >
        <v-tab
          v-for="tab in this.tabs"
          :key="tab.name"
        >
          {{ tab.name }}
        </v-tab>
        <v-tab-item
          v-for="tab in this.tabs"
          :key="tab.name"
          class="my-5"
        >
          <photo-gallery
            :galleryName="tab.name"
          >
          </photo-gallery>
        </v-tab-item>
      </v-tabs>
    </v-row>

  </v-container>
</template>

<script>
import PhotoGallery from '@/components/PhotoGallery.vue';
export default {
  components: {PhotoGallery},
  name: 'PhotoView',
  title: 'Photo',

  data() {
    return {
      loading: true,
      error: null,
      data: null,
      tabs: [
        { key: 0, path: "figure", name: "FIGURE" },
        { key: 1, path: "doll", name: "DOLL" },
        { key: 2, path: "ar", name: "AR" },
        //"NUI",
        { key: 3, path: "other", name: "OTHER" },
        { key: 4, path: "g4u", name: "G4U" },
        { key: 5, path: "koikatsu", name: "KOIKATSU" },
      ],
      currentTab: null,
      tabChangeCnt: 0,
    };
  },
  methods: {
    tabChange() {
      if (this.tabChangeCnt === 0) {
        this.tabChangeCnt++;
        return;
      }
      let key = this.currentTab;
      let path = null;
      this.tabs.forEach((tab => {
        if (key === tab.key) {
          path = tab.path;
        }
      }));
      this.$router.push(
        {
          path: 'photo',
          query: {
            gallery: path,
          }
        }
      );
    }
  },
  computed: {
    imgHeight () {
      // if (this.$vuetify.breakpoint.width > 1200) {
      //   return 1200 * 0.25;
      // }
      // else{
      //   return this.$vuetify.breakpoint.width * 0.25;
      // }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 230
        case 'md': return 280
        case 'lg': return 300
        case 'xl': return 300
      }
      return null;
    }
  },
  mounted() {
    const path = this.$route.query.gallery;
    let key = null;
    if (path) {
      this.tabs.forEach((tab => {
        if (path === tab.path) {
          key = tab.key;
        }
      }));
      if (key) {
        this.currentTab = key;
      }
    }
  }
};
</script>