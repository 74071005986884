<template>
  <v-card>
    <v-footer class="pa-0 ma-0">
      <v-card
        flat
        tile
        width="100%"
        class="blue-grey darken-2 text-center"
      >
        <v-card-text>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>reitoumikan</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style>

</style>